import React from 'react';
import logo from 'assets/Logo.svg';
import smallLogo from 'assets/cpamg-horizontal.png';

import './styles.css';
import 'common/common-styles.css';

export const Header = () => {
  const url = window.location.origin;

  return (
    <div id='header'>
      <div className='logo-container'>
        <div className='logo__overlay' />
        <a href={`${url}#home`}>
          <img className='logo-large' src={logo} alt='CPAMG Logo' />
          <img className='logo-small' src={smallLogo} alt='CPAMG Logo' />
        </a>
      </div>
      <div />
      <nav className='nav'>
        <a href={`${url}#about`}>about</a>
        <a href={`${url}#services`}>services</a>
        <a href={`${url}#contact`} className='contact-nav-item'>
          get in touch
          <svg xmlns='http://www.w3.org/2000/svg' width='25px' height='25px' viewBox='0 0 512 512'>
            <title>Paper Plane</title>
            <path
              d='M53.12 199.94l400-151.39a8 8 0 0110.33 10.33l-151.39 400a8 8 0 01-15-.34l-67.4-166.09a16 16 0 00-10.11-10.11L53.46 215a8 8 0 01-.34-15.06zM460 52L227 285'
              fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='32' />
          </svg>
        </a>
      </nav>
    </div>
  );
};
