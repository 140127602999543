import React from 'react';

import './styles.css';
import 'common/common-styles.css';

export const Title = () => {
  return (
    <div id='home' className='section'>
      <div className='home_title_container'>
        <h1>
          Helping our partners<br /> maintain their<br /> properties
        </h1>
      </div>
    </div>
  );
};
